$(document).ready(function () {
    if ($('.slider.variant1 .slider').length > 0){
      $('.slider.variant1 .slider').slick('slickSetOption', 'autoplaySpeed', 15000, true);
    }
    showLogo();
});

$(document).ajaxStop(function () {

    if($('div#SN_Prive').length > 0){
        $('#editPersonAnchor').text('Privégegevens bewerken');
    }
    if($('.edit_person_container').length > 0){
        $('.passwordvalidationmessage span').text('Voor het doorvoeren van de wijzigingen is het opgeven van je wachtwoord uit veiligheidsoogpunt verplicht.');
    }
    if($('.edit_function_container').length > 0){
        $('.CrmSiteProfileMutationsEditFunctionRenderControl_telefoon span').text('Algemeen telefoonnummer');
        $('.CrmSiteProfileMutationsEditFunctionRenderControl_startdatum span').text('Startdatum: vul hier de datum in waarop je bij deze werkgever/eigen praktijk gestart bent.');
        $('.CrmSiteProfileMutationsEditFunctionRenderControl_einddatum span').text('Einddatum: vul hier de datum in waarop je bij deze werkgever/eigen praktijk gestopt bent.');
        $('.passwordvalidationmessage span').text('Voor het doorvoeren van de wijzigingen is het opgeven van je wachtwoord uit veiligheidsoogpunt verplicht.');
    }
    if ($('.add_function_companyifo_container').length > 0){
        $('.CrmSiteProfileMutationsAddFunctionRenderControl_bezoekadres span').text('Adres');
        $('.CrmSiteProfileMutationsAddFunctionRenderControl_doorkiesnummer span').text('Telefoonnummer');
        $('.CrmSiteProfileMutationsAddFunctionRenderControl_telefoon span').text('Algemeen telefoonnummer');
        $('.CrmSiteProfileMutationsAddFunctionRenderControl_startdatum span').text('Startdatum: vul hier de datum in waarop je bij deze werkgever/eigen praktijk gestart bent.');
        $('.CrmSiteProfileMutationsAddFunctionRenderControl_einddatum span').text('Einddatum: vul hier de datum in waarop je bij deze werkgever/eigen praktijk gestopt bent.');
        $('.passwordvalidationmessage span').text('Voor het doorvoeren van de wijzigingen is het opgeven van je wachtwoord uit veiligheidsoogpunt verplicht.');
    }
    if ($('.delete_function_container').length > 0){
        $('.passwordvalidationmessage span').text('Voor het doorvoeren van de wijzigingen is het opgeven van je wachtwoord uit veiligheidsoogpunt verplicht.');
    }

    // Voeg lidmaatschap toe (afkomstig uit SP)
    if($('.SN_Medewerkers').length > 0){
        var lid = $('.Lidmaatschap').text();
        if($('.Lidmaatschap > span').length > 0){
            $('table.attributes').prepend('<tr><td>Lid soort:</td><td>'+lid+'</td></tr>');
        }
        $('td[class*="_Fax_Label Employee"]').closest('tr').remove();
        $('td[class*="SN_MedewerkerData_Label SN_MedewerkerData_Bedrijfsnaam_Label"]').text("Bedrijfsnaam:");
        // $("<p style='max-width:500px;'>Kloppen de bedrijfsgegevens niet? Mail bureau NVRG. Ben je niet meer werkzaam? Vul einddatum in en voeg nieuwe werkgever/eigen praktijk toe.</p>").insertBefore(".SN_Medewerkers");
    }


    $('#Row_3_Block_1_Pages').find('a').each(function (e) {
        ($(this).attr('target', '_blank'));
    });

    var noMeetingsfound = $(".meetingsNotFound").text();
    if (noMeetingsfound === 'Er zijn geen bijeenkomsten gevonden.') {
        $(".meetingsNotFound").text("Er zijn geen activiteiten gevonden.");
    }

    var addFunction = $("#addFunctionAnchor").text();
    var editFunction = $("a[id*='anchorEditFunction']").text();
    var Function = $("label[for*='Block_EditProfile_SN_Medewerker_RadioButton']").text();

    if (addFunction === 'Functie toevoegen') {
        $("#addFunctionAnchor").text('Voeg werkgever/eigen praktijk toe');
    }
    if (editFunction.length > 0) {
        $("a[id*='anchorEditFunction']").text('Bewerken gegevens werkgever/eigen praktijk');
    }

    if (Function.length > 0) {
        $("label[for*='Block_EditProfile_SN_Medewerker_RadioButton']").text('Werkzaam bij:');
    }

});

function showLogo (){
    var HTMLsource = document.getElementById('logo-uri');
    if(HTMLsource){
        var link = HTMLsource.querySelector('a')
        var imageURI = link.getAttribute('href');

        //todo fallback maken als iamgeurl leeg is.
        if(HTMLsource && imageURI !=''){
            var image = document.createElement('img');
            image.setAttribute('src', imageURI.trim())
            HTMLsource.innerText = '';
            HTMLsource.appendChild(image);
            HTMLsource.style.display = 'block';    
        }
    }
}
